import EventEmitter from "eventemitter3";
import gsap from "gsap";
import { addClass, hasClass, removeClass } from "../../utils/css-utils";
import { addEventListener } from "../../utils/event-utils";
import CensusMap from "./census-map";
import Counter from "./counter";
import Images from "./images";
import Form from "./form";
import Illustrations from "./illustations";
import Request from "../../utils/request";
import { toArray } from "lodash";

export default class Census extends EventEmitter {
  constructor(...args) {
    super();
    this.classname = "c-census-container";
    this.current_index = 0;
    this.results = {};
    this.action = "hb_censuses_create_ajax";
    this.timeline = null;
    this.fake_index = 0;
    this.fake_amount = null;
    this.init();
  }
  init() {
    this.initDomElements();
    this.initIllustrations();
	this.initImageInput();
    this.initCounters();
    this.initMap();
    this.initForm();
    this.addEventListeners();
    this.showStep();
    this.generateDots();
  }
  initDomElements() {
    this.$el = document.querySelector(".js-" + this.classname);
    this.$steps = this.$el.querySelectorAll(".js-step");
    this.fake_amount = this.$steps.length;
    this.$dotsContainer = this.$el.querySelector(".js-dots");
    this.$result = this.$el.querySelector(".js-result");
    this.$validate_buttons = this.$el.querySelectorAll(
      "[data-state='validate']"
    );
    this.$answers = this.$el.querySelectorAll(".js-value");
    this.$map = this.$el.querySelector(".js-map");
    this.$loader = this.$el.querySelector(".js-loader");
    this.$form = this.$el.querySelector(".js-form");
    this.$errorMessage = this.$el.querySelector(".js-error-msg");
  }
  initCounters() {
    this.$counters = [];
    this.$el
      .querySelectorAll(".js-counter")
      .forEach((counter) => new Counter(counter, this));
  }
  initImageInput() {
    if (this.$el.querySelector(".js-images")) {
      new Images(this.$el.querySelector(".js-images"), this);
    }
  }
  initMap() {
    new CensusMap(this.$map, this);
  }
  initForm() {
    new Form(this.$form, this);
  }
  initIllustrations() {
    new Illustrations(this);
  }

  addEventListeners() {
    this.on(MANAGE_CENSUS_EVENT.NEXT_STEP, this.nextStep.bind(this));
    this.on(MANAGE_CENSUS_EVENT.CENSUS_END, this.showResult.bind(this));
    this.on(MANAGE_CENSUS_EVENT.SET_ANSWER_VALUE, this.setValue.bind(this));
    this.on(
      MANAGE_CENSUS_EVENT.SHOW_NO_VALUE_MSG,
      this.showErrorMsg.bind(this)
    );

    addEventListener(this.$validate_buttons, "click", () =>
      this.emit(MANAGE_CENSUS_EVENT.NEXT_STEP)
    );
    addEventListener(this.$answers, "click", this.setValue.bind(this));
  }
  showStep() {
    this.emit(
      MANAGE_CENSUS_EVENT.NEXT_ILLUSTRATIONS,
      JSON.parse(this.getCurrentStep().getAttribute("data-illustrations"))
    );
    this.timeline = gsap.timeline({ paused: true });
    this.timeline.to(this.getCurrentStep(), {
      visibility: "visible",
    });
    this.timeline.fromTo(
      this.getCurrentStep(),
      { opacity: 0, scale: 1.1, pointerEvents: "none" },
      { opacity: 1, scale: 1, pointerEvents: "all" }
    );
    this.timeline.play();
  }
  hideStep(index) {
    this.timeline = gsap.timeline({ paused: true });
    this.timeline.fromTo(
      this.getCurrentStep(),
      { opacity: 1, scale: 1, pointerEvents: "all" },
      { opacity: 0, scale: 0.95, pointerEvents: "none" }
    );
    this.timeline.to(this.getCurrentStep(), {
      visibility: "hidden",
    });
    this.timeline.play();
    let callBack;
    if (index) {
      this.stepIndex = index;
      callBack = this.setStepIndex.bind(this);
    } else {
      callBack = this.incrementStep.bind(this);
    }
    this.timeline.eventCallback("onComplete", callBack);
  }
  nextStep() {
    setTimeout(() => {
      if (this.verifyValue()) {
        this.hideStep();
      } else {
        this.emit(
          MANAGE_CENSUS_EVENT.SHOW_NO_VALUE_MSG,
          "Veuillez choisir une option"
        );
      }
    }, 100);
  }
  navigateToStep(e) {
    const index = e.currentTarget.getAttribute("data-index");
    if (index < this.fake_index) {
      this.hideStep(e.currentTarget.getAttribute("data-index"));
    }
  }
  setStepIndex() {
    this.fake_index = this.stepIndex;
    this.current_index = this.stepIndex;
    this.generateDots();
    this.showStep();
    this.setStepNumber();
    this.stepIndex = null;
  }
  incrementStep() {
    if (this.current_index < this.$steps.length - 1) {
      const skipIndex =
        this.$steps[this.current_index].getAttribute("data-skip");
      if (skipIndex) {
        if (
          JSON.parse(
            this.$steps[this.current_index].getAttribute("data-answer")
          ) == "false"
        ) {
          for (let i = this.current_index + 1; i < skipIndex; i++) {
            addClass(this.$steps[i], "disable");
          }
          this.current_index = skipIndex * 1;
          this.fake_amount = this.$steps.length - this.fake_index;
        } else {
          this.current_index++;
          this.fake_amount = this.$steps.length;
          removeClass(this.$steps, "disable");
        }
      } else {
        this.current_index++;
      }
      this.fake_index++;
      this.generateDots();
      this.showStep();

      this.setStepNumber();
    } else {
      this.emit(MANAGE_CENSUS_EVENT.CENSUS_END);
    }
  }

  async showResult() {
    this.$loader.classList.add('loading')
    await this.getResult();
    this.$loader.classList.remove('loading')
    this.timeline = gsap.timeline({ paused: true });
    this.timeline.to(this.$result, {
      visibility: "visible",
    });
    this.timeline.fromTo(
      this.$result,
      { opacity: 0, scale: 0.9, pointerEvents: "none" },
      { opacity: 1, scale: 1, pointerEvents: "all" }
    );
    this.timeline.play();
  }
  setButtonsState(button) {
    button
      .closest(".js-step")
      .querySelectorAll(".js-value")
      .forEach((btn) => {
        if (hasClass(btn, "selected")) {
          removeClass(btn, "selected");
        }
      });
    addClass(button, "selected");
  }
  verifyValue() {
    return this.getCurrentStep().getAttribute("data-answer") ? true : false;
  }
  setValue(e) {
    let value;
    if (e.target) {
      value = e.currentTarget.getAttribute("value");
      this.setButtonsState(e.currentTarget);
    } else {
      value = e;
    }
    if (this.current_index == 0 && value == "rustic") {
      this.$steps[2].setAttribute(
        "data-illustrations",
        JSON.stringify(["clouds", "nests_rustic"])
      );
    } else {
      this.$steps[2].setAttribute(
        "data-illustrations",
        JSON.stringify(["clouds", "nests"])
      );
    }
    this.getCurrentStep().setAttribute("data-answer", JSON.stringify(value));
  }
  showErrorMsg(message) {
    this.$errorMessage.innerText = message;
    gsap.to(this.$errorMessage, { y: 0, duration: 0.5 });
    gsap.to(this.$errorMessage, { y: "-100%", duration: 0.5, delay: 3 });
  }
  generateDots() {
    this.$dotsContainer.textContent = "";
    for (let i = 0; i < this.$steps.length; i++) {
      if (hasClass(this.$steps[i], "disable")) {
        continue;
      }
      const el = document.createElement("div");
      el.setAttribute("data-index", i);
      if (i <= this.current_index) {
        addClass(el, "active");
      }
      this.$dotsContainer.append(el);
    }
    addEventListener(
      this.$dotsContainer.querySelectorAll("div.active"),
      "click",
      this.navigateToStep.bind(this)
    );
  }
  setStepNumber() {
    this.getCurrentStep().querySelector(".js-surtitle").innerText = `ETAPE ${
      this.fake_index * 1 + 1
    }`;
  }

  getCurrentStep() {
    return this.$steps[this.current_index];
  }
 async getResult() {
    this.$steps.forEach((step) => {
      this.results[step.getAttribute("data-step")] = JSON.parse(
        step.getAttribute("data-answer")
      );
    });
    this.results.first_hirondelle =
      this.$el.querySelector(".js-checkbox").checked;
	  for (let i = 0; i < this.results.images.length; i++) {
		this.results.images[i] = new File([await (await fetch(this.results.images[i].src)).blob()], this.results.images[i].name,{type:this.results.images[i].type});
	  }
	  const formData = new FormData();
	  for ( var key in this.results ) {
		if (typeof this.results[key] == "object" && key != "images") {
			formData.append(key, JSON.stringify(this.results[key]));
		}else if (typeof this.results[key] == "object" && key == "images") {
			this.results[key].forEach(image=>{
				formData.append(key + '[]', image);
			})
		}else{
			formData.append(key, this.results[key]);
		}
	  }
	  const response = await fetch(window.HB.ajax_url + "?action=" + this.action, {
		method: 'POST',
		body: formData
	  });
  }
}

export const MANAGE_CENSUS_EVENT = {
  CENSUS_START: "census-start",
  CENSUS_END: "census-end",
  NEXT_STEP: "census-next-step",
  SET_ANSWER_VALUE: "census-set-value",
  SHOW_NO_VALUE_MSG: "census-show-no-value-message",
  NEXT_ILLUSTRATIONS: "census-next-illustrations",
};
