import EventEmitter from "eventemitter3";
import { addEventListener } from "../../utils/event-utils";
import { MANAGE_CENSUS_EVENT } from "./census";

export default class Images extends EventEmitter {
	constructor($el,census) {
		super();
		this.$el = $el;
		this.census = census;
        this.imagesArray = []
        this.currentIndex = 0
        this.maxDone = false
		this.init();
	}
	init() {
		this.initDomElements();
		this.addEventListeners();
	}
	initDomElements() {
		this.$addButton = this.$el.querySelector("input[type='file']");
		this.$images = this.$el.querySelectorAll(".js-image");
        this.$removeBtn = this.$el.querySelectorAll(".js-remove-btn");
	}
	addEventListeners() {
		this.$addButton.onchange = this.addPhoto.bind(this)
        addEventListener(this.$removeBtn,'click',this.removePhoto.bind(this))
	}
	addPhoto(e){
        if (this.currentIndex < 6) {
            Array.from(e.target.files).forEach(image => {
                this.imagesArray[this.currentIndex] = image
                this.currentIndex++
            });
            this.$addButton.value = null 
        }
        if (this.currentIndex == 6) {
            this.census.emit(
                MANAGE_CENSUS_EVENT.SHOW_NO_VALUE_MSG,
                "Nombre maximum de photos atteint"
              );
        }
        this.handlePreview()
    }
    removePhoto(e){
        this.currentIndex--
        const index = e.target.getAttribute('data-index')*1
        this.imagesArray.splice(index,1)
        this.$images[index + 1 > 5 ? 0 : index + 1].querySelector('.preview').style.backgroundImage = "" 
        this.$images[index + 1 > 5 ? 0 : index + 1].classList.remove('has-photo')
        this.handlePreview()
    }
    handlePreview(){
        this.$images.forEach(image=>{
            image.querySelector('.preview').style.backgroundImage = ""
            image.classList.remove('has-photo')
        })
        this.imagesArray.forEach(async (image,index)=>{
            this.$images[index + 1 > 5 ? 0 : index + 1].querySelector('.preview').style.backgroundImage = `url(${URL.createObjectURL(image)})` 
            this.$images[index + 1 > 5 ? 0 : index + 1].classList.add('has-photo')
        })
		this.census.emit(MANAGE_CENSUS_EVENT.SET_ANSWER_VALUE,this.imagesArray.map(image=> {return {src: URL.createObjectURL(image),name:image.name,type:image.type}}))
    }
}
