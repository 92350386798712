import mapboxgl from "mapbox-gl";
import ZoomControl from "mapbox-gl-controls/lib/zoom";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { MANAGE_CENSUS_EVENT } from "./census";
import SearchBar from "./search-bar";
import EventEmitter from "eventemitter3";
import { addClass, removeClass } from "../../utils/css-utils";
import { getAddressInfo } from "@mapbox/mapbox-gl-geocoder/lib/utils";
import {length,lineString} from "@turf/turf"
export default class CensusMap extends EventEmitter {
	constructor($el, census) {
		super();
		this.$el = $el;
		this.census = census;
		this.mapMaxZoom = 17;
		this.zoom = 4.2;
		this.currentLocation = null
		this.markerData = {center:null,info:null}
		this.initDomElements();
		this.initMapboxMap();
		this.initSearchBar();
		this.addEventListeners();
	}
	initDomElements() {
		this.$mapContainer = this.$el.querySelector(".js-map");
		this.$searchbar = this.$el.querySelector(".js-search-bar");
	}
	initSearchBar() {
		new SearchBar(this.$searchbar, this);
	}
	initMapboxMap() {
		if (this.$mapContainer) {
			const activeLocation = this.getActiveLocationCenterAndZoom();

			// Creating the Mapbox map
			mapboxgl.accessToken = window.HB.mapbox_access_token;
			this.mapboxMap = new mapboxgl.Map({
				container: this.$mapContainer,
				style: "mapbox://styles/chasseurdefrance/ckb7ysjjs4s9c1imy2f780lxc",
				center: activeLocation.center,
				zoom: activeLocation.zoom,
				maxZoom: this.mapMaxZoom,
				attributionControl: false,
				scrollZoom: false,
			});

			// Adding attribution and zoom controls
			this.mapboxMap.addControl(
				new mapboxgl.AttributionControl({ compact: true })
			);
			this.mapboxMap.addControl(new ZoomControl(), "top-right");
			this.geocoder = new MapboxGeocoder({
				accessToken: window.HB.mapbox_access_token,
				mapboxgl: mapboxgl,
				marker: false,
				countries: "fr",
				reverseGeocode: true,
				autocomplete:false,
				limit:3
			});
			this.mapboxMap.addControl(this.geocoder, "top-left");
			this.mapboxMap.on("click", this.setMarkerFromClick.bind(this));
			this.marker = new mapboxgl.Marker({ draggable: true, color: "#86A62C" });
		}
	}

	addEventListeners() {
		this.addMapboxMapEventListeners();
		this.on(
			MANAGE_CENSUS_MAP_EVENT.SET_MAP_POSITION,
			this.setMarkerFromSearchBar.bind(this)
		);
		this.on(MANAGE_CENSUS_MAP_EVENT.SHOW_MAP, this.showMap.bind(this));
	}

	addMapboxMapEventListeners() {
		if (this.$mapContainer && this.mapboxMap) {
			// 'Mouse Enter' & 'Mouse Leave' listeners on clusters layers
			const addCursor = () =>
				(this.mapboxMap.getCanvas().style.cursor = "pointer");
			const removeCursor = () => (this.mapboxMap.getCanvas().style.cursor = "");
			this.mapboxMap.on("mouseenter", "clusters", addCursor);
			this.mapboxMap.on("mouseleave", "clusters", removeCursor);
			this.mapboxMap.on("mouseenter", "unclustered-point", addCursor);
			this.mapboxMap.on("mouseleave", "unclustered-point", removeCursor);
			this.geocoder.on("clear", () => {
				this.marker.remove();
			});
			this.geocoder.on("result", (data) => {
				const result = data.result;
				if (result) {
					this.markerData.info = getAddressInfo(result);

					if (this.markerData.info.country === "France") {
						this.markerData.center = result.center;
						this.currentLocation = result.center;
						this.census.emit(MANAGE_CENSUS_EVENT.SET_ANSWER_VALUE, this.markerData);
						this.marker.setLngLat(result.center).addTo(this.mapboxMap);
					} else if (this.markerLngLat) {
						this.marker.setLngLat(this.markerLngLat).addTo(this.mapboxMap);
						setTimeout(() => {
							this.marker.on("dragend", this.setMarkerFromDrag.bind(this));
						},100)
						this.geocoder.query(
							`${this.markerData.center[1]}, ${this.markerData.center[0]}`
						);
					} else {
						this.marker.remove();
					}
				}
			});
		}
	}
	getActiveLocationCenterAndZoom() {
		return {
			center: [2.348016, 48.85514],
			zoom: this.zoom,
		};
	}
	showMap() {
		removeClass(
			this.census.$steps[this.census.current_index],
			"has-search-bar"
		);
		addClass(this.census.$steps[this.census.current_index], "has-map");
		this.mapboxMap.resize();
	}
	setMarkerFromClick(e) {
		this.geocoder.clear();
		this.markerData.center = e.lngLat
		this.census.emit(MANAGE_CENSUS_EVENT.SET_ANSWER_VALUE, this.markerData);
		this.marker.setLngLat(e.lngLat).addTo(this.mapboxMap);
	}
	setMarkerFromDrag() {
		const position = this.marker.getLngLat();
		this.markerData.center = [position.lng,position.lat]
		this.census.emit(MANAGE_CENSUS_EVENT.SET_ANSWER_VALUE, this.markerData);

	}
	setMarkerFromSearchBar(data) {
		this.mapboxMap.flyTo({center:[data.longitude, data.latitude],zoom: 16,
			speed: 2,
			curve: 1,})
			this.markerData.center = [data.longitude, data.latitude];
			this.currentLocation = [data.longitude, data.latitude];
			this.census.emit(MANAGE_CENSUS_EVENT.SET_ANSWER_VALUE, this.markerData);
		this.marker
			.setLngLat([data.longitude, data.latitude])
			.addTo(this.mapboxMap);
		setTimeout(() => {
			this.marker.on("dragend", this.setMarkerFromDrag.bind(this));
		},100)
	}
	destroy() {}
}
export const MANAGE_CENSUS_MAP_EVENT = {
	SET_MAP_POSITION: "set-map-position",
	SHOW_MAP: "show-map",
};
